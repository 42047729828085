.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 450px;
  border-radius: 10px;
  border: none;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1),
              -5px -5px 15px rgba(255, 255, 255, 0.7);
  padding: 10px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.product-card:hover {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2),
              -10px -10px 20px rgba(255, 255, 255, 0.9);
}


/* productcard.css */
.product-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease-in-out;
}

.product-image img.lazy-loaded {
  filter: blur(0);
}

.product-card {
  position: relative;
}

.product-card.hovered .second-image {
  display: block;
}

.product-image {
  position: relative;
}

.second-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.second-image.show {
  display: block;
}


.product-name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  height: 40px;
}

.product-size {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.view-details-btn {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  border-color: #757575;
  background-image: linear-gradient(to bottom, #000000, #000000);
  color: rgb(255, 255, 255);
  transition: transform 0.2s ease-in-out;
  font-size: 16px;
  cursor: pointer;
}

.view-details-btn:hover {
  background-image: linear-gradient(to bottom, #000000, #000000);
  
  color: white;
}


.product-card .original-price {
  text-decoration: line-through;
  margin-right: 5px;
  
}

.product-card .discounted-price {
  font-weight: 700;
  color: #2d9f00;
}

.sale-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 400;
  z-index: 2;
}
.views-count {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 400;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .product-card {
    max-width: 95%;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
  }
  .product-cards-container{
    margin-top: 20px;
  }
  .product-image{
    display: flex;
    align-self: center;
    width: 80%;
    position: relative; /* Add this line to make the child elements positioned relative to the product-image */
    color: white;

  }

  .product-card .original-price {
    text-decoration: line-through;
    margin-right: 5px;
  }
  
  .product-card .discounted-price {
    font-weight: 700;
    color: #2d9f00;
  }
  .sale-badge {
    position: absolute;
    top: 10px;
    left: 44px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 400;
    z-index: 2;
  }
  .views-count {
    position: absolute;
    top: 15px; /* Adjust the bottom position to properly align the views count */
    right: 50px; /* Adjust the right position to properly align the views count */
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 400;
    z-index: 2;
  }
}