@media only screen and (min-width : 992px){
    .collection-page {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .sort-container {
        display: flex;
        align-items: center;
        top: 18rem;
        position: absolute;
        padding-left: 20px;
        z-index: 0; /* Set a higher z-index to make the sort container appear above other elements */

      }
      
      .sort-container label {
        margin-right: 10px;
      }
      
      .sort-container select {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .product-cards-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 100px;
        max-width: 1200px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 50px;
      }

      .sort-options {
        margin-bottom: 20px;
      }
      
      .sort-options select {
        margin-right: 10px;
      }

      .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 30px;
        align-items: center; /* Center the items vertically */
      }
      
      .pagination-button {
        border: none;
        padding: 10px;
        margin: 0 5px;
        cursor: pointer;
        background-color: #000000;
        color: #ffffff;
        font-size: 18px;
        border-radius: 5px;
        height: 40px;
        box-shadow: 3px 3px 8px #bcbcbc, -3px -3px 8px #ffffff;
      }
      
      .pagination-button.active {
        background-color: #5a67ff;
        color: #ffffff;
      }
      
      .pagination-button.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      /* Smaller arrow buttons */
      .pagination-button.pagination-arrow {
        padding: 5px;
        font-size: 18px;
        height: 30px; /* Set your desired height here */
        border-radius: 5px;
        color: #000000;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 0px #3c3c3c, 0px 0px 0px #ffffff;
      }
}

@media only screen and (max-width : 768px){
  .showing{
    text-align: center;
  }
  .sort-container {
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
  }
  
  .sort-container label {
    margin-right: 10px;
    color: black;
  }
  
  .sort-container select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    align-items: center; /* Center the items vertically */
  }
  
  .pagination-button {
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    font-size: 18px;
    border-radius: 5px;
    height: 40px;
    box-shadow: 3px 3px 8px #bcbcbc, -3px -3px 8px #ffffff;
  }
  
  .pagination-button.active {
    background-color: #5a67ff;
    color: #ffffff;
  }
  
  .pagination-button.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  /* Smaller arrow buttons */
  .pagination-button.pagination-arrow {
    padding: 5px;
    font-size: 18px;
    height: 30px; /* Set your desired height here */
    border-radius: 5px;
    color: #000000;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 0px #3c3c3c, 0px 0px 0px #ffffff;
  }
}

