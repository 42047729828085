    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
      .menu-container{
        display: none;
      }
      .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background: #cc2b5e;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #cc2b5e, #753a88);
        /* Chrome 10-25, Safari 5.1-6 */
        /* background: linear-gradient(to right, #cc2b5e, #753a88); */
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        background: linear-gradient(to right, #5a67ff, #40af62)
      }

            .logo-container img {
              cursor: pointer;
        
      }

      .popular-searches {
        margin-top: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
      }
      
      .popular-searches-header {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 16px;
      }
      
      .popular-searches ul {
        list-style-type: none;
        padding: 0;
      }
      
      .popular-searches li {
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 22px;
        color: rgb(96, 96, 96);
      }
      
      .popular-searches li:hover {
        color: black;
      }
      
      
      .jfslogo {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1025px;
        margin-right: 50px;
        cursor: 'pointer'
      }
      
      .search-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      
      .search-icon-container svg {
        color: #ffffff;
        height: 20px;
      }
      
      .search-container {
        position: relative;
        display: none;
        padding: 10px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        transform-origin: top center;
        transform: scaleY(0);
        transition: opacity 0.3s ease, transform 0.3s ease;
        z-index: 1;
        border: 1px black;
      
        /* Add these styles for animation effect */
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease;
      }
      
      .search-container.open {
        display: block;
        opacity: 1;
        transform: scaleY(1);
      
        /* Add these styles for animation effect */
        max-height: 200px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
        
        .search-container.open:focus{
  outline: none;
}
      
      
      /* Add transitions to animate the search bar opening and closing */
      .search-container {
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }
      
      
      /* Rotate the search icon when the search bar is open */
      .search-icon-container.open svg {
        transform: rotate(45deg);
      }
      
      .search-container form {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .search-container input[type="text"] {
        height: 30px;
        padding: 5px;
        border-color: white;
        border-radius: 10px;
        background-color: #ffffff;
        font-size: 16px;
        color: #000000;
        flex: 1;
      }
      
      .search-container input[type="text"]::placeholder {
        color: #999999;
      }
      
      .search-container button[type="submit"] {
        height: 40px;
        padding: 5px 20px;
        margin-left: 10px;
        border: none;
        border-radius: 4px;
        background-color: #333333;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .search-container button[type="submit"]:hover {
        background-color: #7b7b7b;
      }
      
    
      .collection {
        position: relative;
        display: inline-block;
        margin: 20px;
        color: white;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
      }

    
      
      .collection::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: white;
        transition: width 0.3s ease-in-out;
      }
      
      .collection:hover::before {
        width: 100%;
      }

      
      
      .faqs {
        position: relative;
        display: inline-block;
        margin: 5px;
        color: white;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
      }
      
      .faqs::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: white;
        transition: width 0.3s ease-in-out;
      }
      
      .faqs:hover::before {
        width: 100%;
      }

      .giveaways {
        position: relative;
        display: inline-block;
        /* margin: 20px; */
        margin-left: 20px;
        margin-right: 500px;
        color: white;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
    }

    .giveaways::before {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 4px;
      background-color: white;
      transition: width 0.3s ease-in-out;
    }
    
    .giveaways:hover::before {
      width: 100%;
    }

      .hamburgericon{
        display: none;
      }
    }

    @media only screen and (max-width: 767px){
      .collection{
        display: none;
      }
      .faqs{
        display: none;
      }
      .giveaways{
        display: none;
      }
      .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background: #cc2b5e;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #cc2b5e, #753a88);
        /* Chrome 10-25, Safari 5.1-6 */
        /* background: linear-gradient(to right, #cc2b5e, #753a88); */
        background: linear-gradient(to right, #5a67ff, #40af62);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        height: 80px;
      }
      .logo-container img {
        height: 90px;
        display: flex;
        left: 5px;
        position: absolute;
        top: 0;
    }
      .search-icon-container{
        display: none;
      }
      .search-container.open{
        position: relative;
        display: none;
        padding: 10px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        transform-origin: top center;
        transform: scaleY(0);
        transition: opacity 0.3s ease, transform 0.3s ease;
      }
      .search-container form {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .search-container input[type="text"] {
        height: 30px;

        border-color: white;
        background-color: #ffffff;
        font-size: 16px;
        color: #000000;
        flex: 1;
      }
      
      .search-container input[type="text"]::placeholder {
        color: #999999;
      }
      
      .search-container button[type="submit"] {
        height: 40px;
        padding: 5px 20px;
        border: none;
        background-color: #333333;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .search-container button[type="submit"]:hover {
        background-color: #7b7b7b;
      }
      .shippingmessage{
        font-size: 10px;
      }
      .hamburgericon{
        display: block;
        display:flex; 
        position:absolute; 
        right:20px;
      }
      .menu-container {
        display: none;
      }
      
      .menu-container.open {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        /* position: absolute; */
        top: 60px;
        right: 0;
        /* width: 100%; */
        z-index: 1;
        padding: 10px;
        background: #cc2b5e;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #cc2b5e, #753a88);
        /* Chrome 10-25, Safari 5.1-6 */
        /* background: linear-gradient(to right, #cc2b5e, #753a88); */
        background: linear-gradient(to right, #5a67ff, #40af62);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
      
      .menu-container.open .collection,
      .menu-container.open .faqs {
        margin: 10px 0;
        font-size: 1.4rem;
        color: white;
        cursor: pointer;
        text-align: right;
        text-transform: uppercase;
        display: block;
        text-decoration: none;
      }
      
      .menu-container.open .collection:hover,
      .menu-container.open .faqs:hover {
        color: #d4af37;
      }

      .popular-searches {
        margin-top: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
      }
      
      .popular-searches-header {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 16px;
      }
      
      .popular-searches ul {
        list-style-type: none;
        padding: 0;
      }
      
      .popular-searches li {
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 16px;
        color: rgb(96, 96, 96);
      }
      
      .popular-searches li:hover {
        color: black;
      }
    }

    @media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {

      .jfslogo {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 600px;
        margin-right: 50px;
        cursor: 'pointer'
      }

      .popular-searches {
        margin-top: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
      }
      
      .popular-searches-header {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 16px;
      }
      
      .popular-searches ul {
        list-style-type: none;
        padding: 0;
      }
      
      .popular-searches li {
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 22px;
        color: rgb(96, 96, 96);
      }
      
      .popular-searches li:hover {
        color: black;
      }

    }
