@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  position: relative;
  width: 550px;
  margin-bottom: 20px;
}


.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 60px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  border-radius: 50px;
}

.squareShirtPage{
    position: relative;
    display: flex;
    justify-content: center; /* center the flex items horizontally */
    margin-left: 60px;
    margin-top: 4.25em;
  }
  .mobileshirtimg {
    width: 80%;
    border-radius: 20px;
    margin-bottom: 50px;
  }
  .square-contentShirtPage{
    border-radius: 50px;
    height: 550px;
    margin-bottom: 100px;
  }
  
  .productDesc{
    margin-top: 80px;
    max-width: 70%;
    margin-bottom: 20px;
  }

  
  .shirtname{
    font-size: 45px;
  }
  
  .description{
    font-weight: 300;
    font-size: 20px;
  }
  
  .depop-btn,
  .offer-btn {
    margin-top: 20px;
    padding: 15px 40px;
    border-radius: 3px;
    border-color: #111111;
    color: rgb(255, 255, 255);
    transition: transform 0.2s ease-in-out, background-image 1s ease-in-out, color 0.2s ease-in-out;
    margin-bottom: 30px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .depop-btn {
    background-image: linear-gradient(to right, #000000, #000000);
  }
  
  .offer-btn {
    background-image: linear-gradient(to right, #ffffff, #ffffff);
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
  
  .depop-btn:hover {
    transform: scale(1.05);
    background-image: linear-gradient(to right, #ffffff, #ffffff);
    color: rgb(0, 0, 0);
  }
  
  .offer-btn:hover {
    transform: scale(1.05);
    background-image: linear-gradient(to right, #000000, #000000);
    color: rgb(255, 255, 255);
  }
  
  .depop-btn:hover {
    transition: background-image 0.2s ease-in-out, color 0.2s ease-in-out;
    background-image: linear-gradient(to bottom, #000000, #000000);
    color: white;
  }

  .mobile-layout{
    display: none;
  }

  .original-price {
    text-decoration: line-through;
    margin-right: 5px;
  }
  
  .discounted-price {
    font-weight: bold;
    color: #2d9f00; /* Add this line to set the color to green */
  }
  
  @media (max-width: 768px) {
    .squareShirtPage {
      flex-direction: column;
      margin-left: 0;
      margin-top: 0;
    }
    
    .square-contentShirtPage {
      border-radius: 0;
      height: 300px;
      margin-bottom: 30px;
    }
    
    .productDesc {
      max-width: 90%;
      margin: 0 auto;
    }
    .desktop-layout{
        display: none;
    }
    .mobile-layout{
        display: block;
    }
    .mobile-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: center;
      }
      
      .square-contentShirtPage {
        width: 300px;
        height: 300px;
        border-radius: 20px;
        margin-top: 20px;
    }
    .offer-btn {
      padding: 15px 49px;
      border-radius: 3px;
      border-color: #111111;
      color: rgb(0, 0, 0);
      margin-bottom: 30px;
      font-size: 16px;
      cursor: pointer;
      margin-top: -10px;
    }

      .mobile-bottom{
        width: 92%;
        margin-left: 20px;
        margin-bottom: 50px;
      }
      .image-gallery {
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        position: relative;
        width: 85%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 80px);
      border-radius: 20px;
  }
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 45px;
    width: 25px;
    margin-top: 30px;
}

.original-price {
  text-decoration: line-through;
  margin-right: 5px;
}

.discounted-price {
  font-weight: bold;
  color: #2d9f00; /* Add this line to set the color to green */
}
  }

@media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {
  .productDesc {
    margin-bottom: 20px;
    margin-top: 80px;
    max-width: 80%;
    margin-left: 25px;
}

.image-gallery {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  margin-bottom: 20px;
  position: relative;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  width: 100%;
}
.original-price {
  text-decoration: line-through;
  margin-right: 5px;
}

.discounted-price {
  font-weight: bold;
  color: red; /* Add this line to set the color to green */
}
  }
  
