body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.footer {
  color: white;
  background-color: #4101ab;
  height: 167px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  width: 100%;
}
    
    .followtextmobile{
      display: none;
      
    }



  @media only screen and (max-width: 768px) {
    .footerlottie {
      display: none !important;
    }
    .instaicon{
      margin-left: 7px;
    }
    .followtext{
      display: none;
    }

    .followtextmobile{
      display: block;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  
