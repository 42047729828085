.mobilefaq{
    display: none;
}

@media (max-width: 768px) {
    .desktopfaq{
        display: none;
    }
    .mobilefaq{
        display: block;
    }
}